import { Routes } from '@angular/router';

import { authGuard } from './pages/auth';
import { VerifyComponent } from './pages/auth/components/verify/verify.component';

export const routes: Routes = [
  {
    path: 'error',
    loadChildren: () =>
      import('./pages/errors/errors.module').then(m => m.ErrorsModule),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'dashboard',
    canActivate: [authGuard],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'settings',
    canActivate: [authGuard],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./pages/settings/settings.module').then(m => m.SettingsModule),
  },
  {
    path: 'confirm',
    loadChildren: () =>
      import('./pages/confirm/confirm.module').then(m => m.ConfirmModule),
  },
  {
    path: '',
    component: VerifyComponent,
  },
  // { path: '', redirectTo: 'settings', pathMatch: 'full' },
  { path: '**', redirectTo: 'error/404' },
];
