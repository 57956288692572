import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  BehaviorSubject,
  Observable,
  catchError,
  filter,
  share,
  switchMap,
  take,
  throwError,
} from 'rxjs';

import { AuthModel } from '../models/auth.model';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  // private fields
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<AuthModel | undefined>;

  constructor(private authService: AuthService) {
    this.refreshTokenSubject = new BehaviorSubject<AuthModel | undefined>(
      undefined,
    );
  }

  intercept(
    req: HttpRequest<Request>,
    next: HttpHandler,
  ): Observable<HttpEvent<HttpHandler>> {
    const auth = this.authService.currentAuthValue;

    if (auth) {
      if (auth?.logoutOn && auth.logoutOn < Date.now()) {
        this.authService.logout();
      }

      if (auth?.expiresOn && auth.expiresOn < Date.now()) {
        return this.handle401Error(req, next);
      }

      const headers = new HttpHeaders({
        Authorization: `Bearer ${auth.accessToken}`,
      });

      const reqClone = req.clone({ headers });

      return next.handle(reqClone).pipe(
        catchError(err => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            return this.handle401Error(req, next);
          }

          return throwError(err);
        }),
        share(),
      );
    }

    return next.handle(req);
  }

  private handle401Error(req: HttpRequest<Request>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(undefined);

      const auth = this.authService.currentAuthValue;

      if (auth?.refreshToken) {
        return this.authService.refreshToken(auth.refreshToken).pipe(
          switchMap(auth => {
            if (auth?.accessToken) {
              this.isRefreshing = false;

              this.refreshTokenSubject.next(auth);

              const headers = new HttpHeaders({
                Authorization: `Bearer ${auth.accessToken}`,
              });

              const reqClone = req.clone({ headers });

              return next.handle(reqClone);
            }

            return next.handle(req);
          }),
          catchError(err => {
            this.isRefreshing = false;

            return throwError(err);
          }),
        );
      }
    }

    return this.refreshTokenSubject.pipe(
      filter(auth => auth !== null),
      take(1),
      switchMap(auth => {
        if (auth?.accessToken) {
          const headers = new HttpHeaders({
            Authorization: `Bearer ${auth.accessToken}`,
          });

          const reqClone = req.clone({ headers });

          return next.handle(reqClone);
        }

        return next.handle(req);
      }),
    );
  }
}
