import { NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { environment } from '../../environments/environment';

export const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.redirectUri,
  },
  position: 'bottom-left',
  theme: 'classic',
  palette: {
    popup: {
      background: '#000000',
      text: '#ffffff',
      link: '#ffffff',
    },
    button: {
      background: '#ffd100',
      text: '#000000',
      border: 'transparent',
    },
  },
  type: 'info',
  content: {
    message: '',
    dismiss: '',
    allow: '',
    deny: '',
    link: '',
    href: '',
    policy: '',
  },
};
