import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = [];
  private routerEventsSubscr!: Subscription;

  appTitle = 'GLPS';

  private statusChangeSubscription!: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    private translate: TranslateService,
    private cookieService: NgcCookieConsentService,
    private ssrCookieService: SsrCookieService,
  ) {
    this.meta.addTags([{ name: 'robots', content: 'noindex, nofollow' }]);
  }

  ngOnInit() {
    this.assignContentSecurityPolicy();
    this.changePageTitle();
    this.cookiesConsentInit();

    this.translate.onLangChange.subscribe(() => {
      this.setCookieConsentConfig();
    });
  }

  cookiesConsentInit() {
    const isCookieAllowed = this.ssrCookieService.get('IS_COOKIE_ALLOWED');
    if (isCookieAllowed === 'true') this.cookieService.destroy();

    this.setCookieConsentConfig();

    this.statusChangeSubscription = this.cookieService.statusChange$.subscribe(
      () => {
        this.ssrCookieService.set('IS_COOKIE_ALLOWED', 'true', 365);
        this.cookieService.destroy();
      },
    );
  }

  setCookieConsentConfig() {
    this.translate
      .get([
        'cookieContent.message',
        'cookieContent.dismiss',
        'cookieContent.link',
        'cookieContent.policy',
        'cookieContent.href',
      ])
      .subscribe(translation => {
        this.cookieService.getConfig().content =
          this.cookieService.getConfig().content || {};
        this.cookieService.getConfig().content!.message =
          translation['cookieContent.message'];
        this.cookieService.getConfig().content!.dismiss =
          translation['cookieContent.dismiss'];
        this.cookieService.getConfig().content!.link =
          translation['cookieContent.link'];
        this.cookieService.getConfig().content!.policy =
          translation['cookieContent.policy'];
        this.cookieService.getConfig().content!.href =
          translation['cookieContent.href'];

        this.cookieService.destroy();
        this.cookieService.init(this.cookieService.getConfig());
      });
  }

  assignContentSecurityPolicy() {
    const csp = `default-src 'self'; script-src 'report-sample' 'self'; style-src 'report-sample' 'self' 'unsafe-inline'; object-src 'none'; base-uri 'self'; connect-src 'self' https://api-glps.o-i.com https://qa-api-glps.o-i.com https://dev-api-glps-o-i-com.azurewebsites.net https://oidevadb2c.b2clogin.com https://oiadb2c.b2clogin.com https://sr-oiglps.service.signalr.net https://sr-oiglps-dev.service.signalr.net https://sr-oiglps-qa.service.signalr.net wss://sr-oiglps.service.signalr.net wss://sr-oiglps-dev.service.signalr.net wss://sr-oiglps-qa.service.signalr.net; font-src 'self'; frame-src 'self'; img-src 'self' https://stoiglps.blob.core.windows.net https://stoiglpsdev.blob.core.windows.net https://stoiglpsqa.blob.core.windows.net data:; manifest-src 'self'; media-src 'self'; worker-src 'none';`;
    const cspMeta = document.createElement('meta');
    cspMeta.httpEquiv = 'Content-Security-Policy';
    cspMeta.content = csp;
    document.head.appendChild(cspMeta);
  }

  changePageTitle() {
    this.routerEventsSubscr = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
      )
      .subscribe(event => {
        let title = this.appTitle;
        if (event['title']) {
          title = event['title'] + ' - ' + this.appTitle;
        }
        this.title.setTitle(title);
      });
    this.unsubscribe.push(this.routerEventsSubscr);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
    this.statusChangeSubscription.unsubscribe();
  }
}
